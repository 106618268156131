<template>
  <div v-loading="true" style="width: 100vw;height: 100vh;">
  </div>
</template>

<script>
let address = window.global_config.BASE_URL2
import axios from 'axios'
import { local } from "@/utils/storage";

export default {
  methods: {
    async getUserIngo() {
      let res = await axios({
        method: 'post',
        url: `${address}User/My`,
        headers: {
          'Authorization': 'Bearer ' + this.$route.query.token
        }
      })
      local.set("LoadingFalse", true);
      local.set("Token", this.$route.query.token);
      local.set("UserId", res.data.Data.BasePart.Id);
      local.set("RoleType", 'D');
      local.set("UserName", res.data.Data.BasePart.Name);
      local.set('OrganizationName', res.data.Data.BasePart.OrganizationName)
      local.set("currentOrganizationId", res.data.Data.BasePart.OrganizationId);
      local.set("UnitName", res.data.Data.BasePart.WorkUnitName);
      local.set("TitleName", res.data.Data.BasePart.TitleName);
      local.set("Sex", res.data.Data.BasePart.Sex);
      this.$router.push(`/seeProject?Id=${this.$route.query.id}`)
    }
  },
  created() {
    this.getUserIngo()
  }
}
</script>

<style lang="scss" scoped></style>